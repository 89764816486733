import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import ReadMore from './ReadMore';
import { aspectRatio, examples, screenshotSizes } from './examples.json';
import SpreadsheetKeyExtractor from './SpreadsheetKeyExtractor';
import { Away, Column, Logo, Row } from '@altaflow/core';
import { Helmet } from 'react-helmet';
import AltaFlowLogo from './images/alta-flow-mark.svg';
import MapboxLogo from './images/mapbox-logo-black.svg';
import NycCitiBikeImage from './images/nyc-citi-bike_1000px.jpg';
import TLLogo from './images/TL-Horizontal-Black.svg';
import { Button, Classes, Colors, Intent, Tag } from '@blueprintjs/core';
import Nav from './Nav';
// import News from './News';
import ReactPlayer from 'react-player';

const ContentBody = styled.div`
  padding: 10px 20px;
  @media (min-width: 500px) {
    padding: 20px 60px;
  }
  & h1 {
    font-size: 2rem;
  }
  margin: auto;
  max-width: 1500px;
`;

const LogoTitle = styled.h1`
  margin-bottom: 1em;
`;

const Support = styled.p`
  display: flex;
  justify-items: center;
  flex-wrap: wrap;
`;

const AwardImage = styled.img`
  filter: grayscale(80%);
  transition: filter 0.5s;
  &:hover {
    filter: grayscale(20%);
  }
`;

// const SupportLogoLink = styled.a`
//   position: relative;
//   top: 0.14em;
//   transition: opacity 0.2s;
//   opacity: 0.6;
//   margin: 0 22px;
//   &:hover {
//     opacity: 1;
//   }
// `;

// const SupportLogo = ({ src, href }: { src: string; href: string }) => (
//   <SupportLogoLink href={href} target="_blank" rel="noopener noreferrer">
//     <img alt="support logo" src={src} height={22} />
//   </SupportLogoLink>
// );

const NoWrap = styled.span`
  display: flex;
  flex-wrap: nowrap;
`;

const ExampleGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 650px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const HoverableLink = styled(Link)`
  width: 100%;
  overflow: hidden;
  border: 1px solid ${Colors.GRAY2};
  transition: border 0.25s;
  &:hover {
    border: 1px solid ${Colors.LIGHT_GRAY1};
  }
`;
const ExampleGridHoverableLink = styled(HoverableLink)`
  position: relative;
  width: 100%;
  &:hover {
    & > .name {
      color: ${Colors.LIGHT_GRAY1};
    }
  }
`;
const ExampleTitle = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  padding: 4px 7px;
  bottom: 0;
  background: ${Colors.DARK_GRAY2};
  color: ${Colors.GRAY3};
  font-size: 9pt;
  z-index: 2;
  pointer-events: none;
  transition: color 0.25s;
  text-align: center;
  border-top: 1px solid rgba(19, 124, 189, 0.25);
`;
const ExampleImage = styled.div`
  img {
    display: block;
    height: auto;
    width: 100%;
    position: absolute;
    top: 0;
    transform: scale(1);
    transition: transform 0.5s;
    &:hover {
      transform: scale(1.5);
    }
  }
  &:before {
    // persistent aspect ratio trick https://stackoverflow.com/a/51578598/120779
    content: '';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(${1 / aspectRatio} * 100%);
  }
`;
const DemoVideo = styled.div`
  width: 100%;
  margin-bottom: 20px;
  max-width: 500px;
  display: inline-block;
  @media (min-width: 800px) {
    float: right;
    margin-left: 20px;
  }
`;

const ResponsivePlayer = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`;

const ResponsiveReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid ${Colors.GRAY1};
`;

const ListOfSteps = styled.ol`
  margin: 30px 20px;
  & > li {
    margin: 1em 0;
    padding-left: 0.3em;
  }
  @media (max-width: 500px) {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    & > li {
      padding-left: 0;
      margin-left: 0;
    }
  }
`;

// const ListOfUses = styled.div`
//   display: flex;
//   // border-radius: 6px;
//   // background-color: ${Colors.DARK_GRAY3};
//   flex-wrap: wrap;
//   list-style-type: none;
//   padding: 0.5em 1em;
//   margin: 1em 0;
// `;

// const ListOfUsesItem = styled(({ className, children }) => (
//   <Tag className={className} minimal round interactive={false} intent={Intent.NONE}>
//     {children}
//   </Tag>
// ))`
//   margin: 5px;
// `;

const TitleRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Home = () => (
  <>
    <Nav />
    <ContentBody className={Classes.DARK}>
      <Helmet>
        <link href="https://flowmap.altago.site" rel="canonical" />
        <title>Alta Flow - Multimodal flow map visualization tool</title>
      </Helmet>

      <TitleRow>
        <LogoTitle>
          <Logo fontSize={40} collapseWidth={300} url={AltaFlowLogo} />
        </LogoTitle>
      </TitleRow>

      <DemoVideo>
        <ResponsivePlayer>
          <ResponsiveReactPlayer
            url={['/videos/demo_500.webm', '/videos/demo_500.mp4']}
            light={NycCitiBikeImage}
            width="100%"
            height="100%"
            controls={true}
            loop={true}
            playing={true}
          />
        </ResponsivePlayer>
      </DemoVideo>

      <section>
        <p>This is an internal tool intended to help Alta staff understand and communicate traveler behavior and complex origin-destination data.</p>
      </section>

      <section>
        <h2 id="examples">Examples</h2>
        <p>Alta Flow can be used for various kinds of origin-destination data. Below are internally generated Alta Flow maps you can use to demonstrate our abilities to clients.</p>
        <ExampleGrid>
          {examples.map((opts) => {
            let sheet = undefined;
            return (
            <ExampleGridHoverableLink key={opts.key} to={`/${opts.key}${sheet ? `/${sheet}` : ''}${opts.query ? `?${opts.query}` : ''}`}>
              <ExampleImage>
                <ExampleTitle className="name">{opts.name}</ExampleTitle>
                <img
                  width={screenshotSizes[0]}
                  height={Math.floor(screenshotSizes[0] / aspectRatio)}
                  alt={opts.name}
                  src={`/screenshots/${opts.key}${sheet ? `_${sheet}` : ''}_${screenshotSizes[0]}px.jpg`}
                  // srcSet={screenshotSizes.map(w => `/screenshots/${key}__${w}px.jpg ${w}w`).join(',')}
                  // sizes={screenshotSizes.map((w, i) =>
                  //   (i < screenshotSizes.length - 1 ? `(max-width: ${w * 2}px) ` : '') + `${w}px`)
                  //   .join(',')
                  // }
                />
              </ExampleImage>
            </ExampleGridHoverableLink>
          );
            })}
        </ExampleGrid>
      </section>

      {/*<section>*/}
      {/*  <h2>Data preparation helpers</h2>*/}
      {/*  <p>*/}
      {/*    <Button>Convert OD-matrix</Button>*/}
      {/*  </p>*/}
      {/*</section>*/}

      <section>
        <h2 id="tools">Tools</h2>
        <p>
          Our <Link to="/geocoding">Geocoding tool</Link> can help you finding the geographic
          coordinates for locations if you only have their names in your dataset.
        </p>
        <p>
          Use the <Link to="/od-matrix-converter">OD-matrix converter</Link> if your movement counts
          are represented as an OD-matrix.
        </p>
        <p>
          With the <Link to="/in-browser">In-browser flow map</Link> you can visualize OD-data{' '}
          <b>directly in your browser</b> without having to upload the data to Google Sheets.
        </p>
      </section>

      {/* <section>
        <h2 id="privacy">Data privacy</h2>
        <p>
          AltaFlow does not store any of the data the users upload to Google Sheets. The data
          from the spreadsheets is loaded directly into the clients' browsers. We are using{' '}
          <Away href="https://developers.google.com/chart/interactive/docs/querylanguage">
            Google Visualization API
          </Away>{' '}
          for that.
        </p>
        <p>
          We treat the data as private, including the URLs of the spreadsheets and flow maps
          published by the users. We do not disclose the URLs without getting an explicit consent
          from the authors (unless the authors have already disclosed them by linking from a
          publicly accessible website).
        </p>
        <p>
          We collect usage statistics via Google Analytics and track errors via Sentry. We want to
          know how AltaFlow is used and when it fails so that we can improve it. The use of
          Google Analytics involves setting cookies in the users' browsers for detecting recurring
          visits and working sessions.
        </p>
        <p>
          We may try to contact you asking for a permission to add your flow map to the list of
          examples on the homepage of AltaFlow.
        </p>
      </section> */}
    </ContentBody>
  </>
);

export default Home;
